<template>
    <div class="page">
      <div v-if="loading.codes" class="h-100 d-flex justify-center align-center">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
      <BackBtn />
      <BreadCrumbs :items="breadcrumbs" />

      <h1 class="font-lg mb-5">Реферальные ссылки</h1>

      <div class="profile-board mb-5">
            <div class="row">
              <div class="col-12 d-flex align-center justify-space-between" v-if="$store.getters['auth/isAllowed']('add_referralnumber')">
                <v-btn class="ml-auto" color="primary" small _fab outlined @click="createLink()">
                  <!-- <v-icon small class="mr-2">mdi-tab-plus</v-icon>  -->
                  Добавить ссылку
                </v-btn>
              </div>
            </div>
            <v-data-table :headers="headersCodes" :items="links" class="mt-4"
                      :loading="loading.load" loading-text="Загрузка... Пожалуйста подождите"
                      :mobile-breakpoint="300" fixed-header hide-default-footer :items-per-page="-1"
            >
              <template v-slot:item.name="{ item }">
                <span :class="{'text-gray': isExpired(item)}">{{item.name}} </span>
              </template>
              <template v-slot:item.url="{ item }">
                <v-btn text fab small :href="`/ref/${item.number}`" :disabled="!item.number" target="_blank" color="primary">
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.tests="{ item }">
                <span class="text-small" :class="{'text-gray': isExpired(item)}" v-for="(t,i) in item.tests" :key="i">{{ (i?', ': '') + getTest(t) }} </span>
              </template>

              <template v-slot:item.manager="{ item }">
                <v-progress-circular v-if="loadingManagers" size="20" indeterminate color="primary"></v-progress-circular>
                <span v-else class="text-small" :class="{'text-gray': isExpired(item)}">{{ getManager(item.manager) }} </span>
              </template>

              <template v-slot:item.start_date="{ item }">
                <span class="text-small" :class="{'text-gray': isExpired(item)}">{{ $moment(item.start_date).format('DD.MM.YYYY') }} </span>
              </template>

              <template v-slot:item.stop_date="{ item }">
                <span class="text-small" :class="{'text-gray': isExpired(item)}">{{ item.stop_date ? $moment(item.stop_date).format('DD.MM.YYYY') : ''}} </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn fab small text @click="edit(item)" v-if="$store.getters['auth/isAllowed']('change_referralnumber')">
                  <v-icon color="primary">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn fab small text @click="del(item)" v-if="$store.getters['auth/isAllowed']('delete_referralnumber')">
                  <v-icon color="error">mdi-delete-outline</v-icon>
                </v-btn>
                <!-- <v-menu bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text fab small v-bind="attrs" v-on="on" :loading="loading.activate"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-if="!item.promocode_users || !item.promocode_users.length" @click="edit(item)"><v-list-item-title>Редактировать</v-list-item-title></v-list-item>
                        <v-list-item @click="del(item)"><v-list-item-title>Удалить</v-list-item-title></v-list-item>
                      </v-list>
                    </v-menu> -->
              </template>
              <template v-slot:no-data>
                Список ссылок пуст
              </template>
            </v-data-table>

      </div>
      <RefLinkEdit :managedBy="user.id" />
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { PARTNER, ADMIN } from '@/common/constants/userTypes';
import RefLinkEdit from '@/components/refs/RefLinkEdit.vue';
  
export default {
    name: 'RefLinksPage',
    components: {
        RefLinkEdit
    },
    props: ['userId', 'userType'],
    inject: {
      isProfile: {
        default: false
      }
    },
    data() {
      return {
        mainTab: 1,
        showAll: true,
        headersCodes: [
          { text: 'Название', value: 'name', sortable: true,},
          { text: 'Ссылка', value: 'url', sortable: false,},
        //   { text: 'Код', value: 'number', sortable: false,},
          { text: 'Тесты', value: 'tests', sortable: false, width: 400 },
          { text: 'Менеджер', value: 'manager', sortable: false, },
          { text: 'Дата начала', value: 'start_date', sortable: true, },
          { text: 'Дата окончания', value: 'stop_date', sortable: true, },
          { text: '', value: 'actions', sortable: false, align: 'center' },
        ],
        loadingManagers: true,
      };
    },
    computed: {
      ...mapGetters({
        managers: 'users/managers/managers',
        partner: 'users/partners/partner',
        links: 'profile/links/links',
        tests: 'profile/links/tests',
        defaultLink: 'profile/links/defaultLink',
        loading: 'profile/links/loading',
        user: 'auth/user',
      }),
      breadcrumbs() {
        if (this.userType === ADMIN) {
          return [
            { title: 'Личный кабинет', url: '/' },
            { title: 'Промокоды', url: '/promocodes' },
          ];
        }
        if (this.userType === PARTNER) {
          if (!this.partner) return [];
          return this.isProfile ? [
            { title: 'Личный кабинет', url: '/' },
            { title: 'Промокоды', url: '/promocodes' },
          ] : [
            { title: 'Партнеры', url: '/partners/' },
            { title: `${this.partner.last_name} ${this.partner.first_name}`, url: `/partners/${this.partner.id}` },
            { title: 'Промокоды', url: `/partners/${this.partner.id}/promocodes` },
          ];
        }
        return [];
      },
  
    },
    methods: {
      ...mapActions({
        // loadPartner: 'users/partners/loadPartner',
        loadManagers: 'users/managers/loadManagers',
        loadLinks: 'profile/links/loadLinks',
        deleteLink: 'profile/links/deleteLink',
      }),
      ...mapMutations({
        setEditedLink: 'profile/links/setEditedLink',
        setDialogEditShow: 'profile/links/setDialogEditShow',
      }),
      isExpired(item) {
        return item.stop_date ? new Date(this.$moment(item.stop_date)) < new Date() : false
      },
      getTest(id) {
        const test = this.tests.find(el => el.id === id)
        return test ? test.name : 'Тест не найден'
      },
      getManager(id) {
        const test = this.managers.find(el => el.id === id)
        return test ? test.last_name + ' ' + test.first_name : 'Не найден'
      },
      async del(item) {
        await this.deleteLink(item);
      },
      async edit(item) {
        this.setEditedLink(item);
        this.setDialogEditShow(true);
      },
      createLink() {
        this.setEditedLink(null);
        this.setDialogEditShow(true);
      },
      async load() {
        await this.loadLinks({ user_type: this.userType, id: this.userId });
        this.loadingManagers = true
        await this.loadManagers()
        this.loadingManagers = false
      }
    },
    async created() {
      await this.load();
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "src/assets/styles/variables";
  .text-small {
    font-size: 12px;
  }
  </style>
  