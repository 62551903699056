var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(_vm.loading.codes)?_c('div',{staticClass:"h-100 d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('BackBtn'),_c('BreadCrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('h1',{staticClass:"font-lg mb-5"},[_vm._v("Реферальные ссылки")]),_c('div',{staticClass:"profile-board mb-5"},[_c('div',{staticClass:"row"},[(_vm.$store.getters['auth/isAllowed']('add_referralnumber'))?_c('div',{staticClass:"col-12 d-flex align-center justify-space-between"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","small":"","_fab":"","outlined":""},on:{"click":function($event){return _vm.createLink()}}},[_vm._v(" Добавить ссылку ")])],1):_vm._e()]),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headersCodes,"items":_vm.links,"loading":_vm.loading.load,"loading-text":"Загрузка... Пожалуйста подождите","mobile-breakpoint":300,"fixed-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'text-gray': _vm.isExpired(item)}},[_vm._v(_vm._s(item.name)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":"","href":("/ref/" + (item.number)),"disabled":!item.number,"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return _vm._l((item.tests),function(t,i){return _c('span',{key:i,staticClass:"text-small",class:{'text-gray': _vm.isExpired(item)}},[_vm._v(_vm._s((i?', ': '') + _vm.getTest(t))+" ")])})}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [(_vm.loadingManagers)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"primary"}}):_c('span',{staticClass:"text-small",class:{'text-gray': _vm.isExpired(item)}},[_vm._v(_vm._s(_vm.getManager(item.manager))+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small",class:{'text-gray': _vm.isExpired(item)}},[_vm._v(_vm._s(_vm.$moment(item.start_date).format('DD.MM.YYYY'))+" ")])]}},{key:"item.stop_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small",class:{'text-gray': _vm.isExpired(item)}},[_vm._v(_vm._s(item.stop_date ? _vm.$moment(item.stop_date).format('DD.MM.YYYY') : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters['auth/isAllowed']('change_referralnumber'))?_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.$store.getters['auth/isAllowed']('delete_referralnumber'))?_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" Список ссылок пуст ")]},proxy:true}])})],1),_c('RefLinkEdit',{attrs:{"managedBy":_vm.user.id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }