<template>
    <v-dialog v-model="dialogEditShow" max-width="700px">
      <v-card class="pa-4 pa-sm-8">
          <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="text-center">{{editedItem.id ? 'Редактирование ссылки' : 'Создание ссылки'}}</h2>
        <form @submit.prevent="editConfirm" class="mt-4">
          <div class="container">
            <v-text-field label="Название" v-model="editedItem.name" @input="clearError()" :error-messages="nameErrors" outlined dense />

            <v-select _v-if="user.user_type===ADMIN || user.user_type===PARTNER" v-model="editedItem.manager" class=""
                  :items="sortedManagers" label="Менеджер"
                  :item-text="(item) => item.first_name || item.last_name ? `${item.last_name} ${item.first_name} (${item.email})` : 'имя не задано'"
                  item-value="id" outlined dense
                  :_disabled="!$store.getters['auth/isAllowed']('change_partner_of_corpclient')"
                  :error-messages="managerErrors"
            />

            <v-menu ref="menu_start_date" v-model="menu_start_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="!canChangeDates"
                  :value="start_dateFormatted" label="Дата начала" append-icon="mdi-calendar" readonly format="DD.MM.YYYY"
                  @change="$v.start_date.$touch()" @blur="$v.start_date.$touch()"
                  :error-messages="start_dateErrors" v-bind="attrs" v-on="on" outlined dense
            />
              </template>
              <v-date-picker v-model="start_date" scrollable locale="ru-RU" @input="menu_start_date=false" first-day-of-week=1 />
            </v-menu>
            <v-menu ref="menu_stop_date" v-model="menu_stop_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="!canChangeDates"
                  :value="stop_dateFormatted" label="Дата окончания" append-icon="mdi-calendar" readonly format="DD.MM.YYYY"
                  @change="$v.stop_date.$touch()" @blur="$v.stop_date.$touch()"
                  :error-messages="stop_dateErrors" v-bind="attrs" v-on="on" outlined dense
              />
              </template>
              <v-date-picker v-model="stop_date" scrollable locale="ru-RU" @input="menu_stop_date=false" first-day-of-week=1 />
            </v-menu>

            Тесты :
            <v-treeview class="w-100 elevation-1" v-model="selectedTests" :items="tests" selectable dense  />
            <div class="v-messages theme--light error--text ml-4 mt-1" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{testErrors[0]}}</div>
                </div>
            </div>

            <v-switch label="Отчеты доступны кандидату" :input-value="editedItem.can_view_report" @change="editedItem.can_view_report = $event" @click.stop=""
                  hide-details inset class="item-toggle" :ripple="false" dense
                />

            <!-- <div class="row">
              <div class="col-12">
                <v-text-field label="Описание" v-model="editedItem.description" @input="clearError()" outlined dense hide-details />
              </div>
            </div> -->
            <!-- <div v-for="(err, ind) in errors.edit" :key="ind" class="error--text">
              <template v-if="!editedItem.hasOwnProperty(err[0])">
                <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
              </template>
            </div> -->
          </div>

          <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn type="submit" class="w-100" color="primary" :loading="loading.save" :disabled="loading.save">
                Сохранить
              </v-btn>
            </div>
          </div>
        </form>
      </v-card>
    </v-dialog>
</template>

<script>
/*eslint-disable*/
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { maxLength, required, minValue } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import {
  CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
} from '@/common/constants/userTypes';

export default {
    validations: {
      editedItem: {
        name: { required, maxLength: maxLength(80) },
      },
      start_date: {required},
      // stop_date: {required},
    },
    props: ['managedBy'],
    data: () => ({
      selectedTests: [],
      editedItem: {
        id: 0,
        name: '',
        description: "",
        manager: 0,
      },
      menu_start_date: false,
      start_date: '',
      menu_stop_date: false,
      stop_date: '',
      CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
    }),
    watch: {
      async dialogEditShow(newVal) {
        if (newVal) {
          // this.editedItem = cloneDeep(this.editedCandidate);
          this.editedItem = (
            ({ id, name, manager, can_view_report, start_date, stop_date }) =>
            ({ id, name, manager, can_view_report, start_date, stop_date })
          )(this.editedLink);
          this.selectedTests = [...this.editedLink.tests]  
          this.start_date = new Date(this.$moment(this.editedItem.start_date)).toISOString().substr(0, 10)
          this.stop_date = this.editedItem.stop_date ? new Date(this.$moment(this.editedItem.stop_date)).toISOString().substr(0, 10) : null
        }
      },
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
        loading: 'profile/links/loading',
        errors: 'profile/links/errors',
        tests: 'profile/links/tests',
        editedLink: 'profile/links/editedLink',
        managers: 'users/managers/managers',
      }),
      dialogEditShow: {
        get() {
          return this.$store.getters['profile/links/dialogEditShow'];
        },
        set(val) {
          if (!val) {
            this.$v.$reset();
          }
          this.setDialogEditShow(val);
        },
      },
      nameErrors() {
        const errors = [];
        if (!this.$v.editedItem.name.$dirty) return errors;
        if (!this.$v.editedItem.name.required) errors.push(MESSAGES.REQUIRED);
        if (!this.$v.editedItem.name.maxLength) errors.push(MESSAGES.MAX_LENGTH(80));
        return this.errors.edit.name ?? errors;
      },
      managerErrors() {
        const errors = [];
        return this.errors.edit.manager ?? [];
      },      
      testErrors() {
        const errors = [];
        return this.errors.edit.tests ?? [];
      },      
      start_dateErrors() {
        const errors = [];

        if (this.start_date && this.stop_date) {
          const startDate = new Date(this.start_date);
          const stopDate = new Date(this.stop_date);
          if (startDate > stopDate) {
            errors.push('Дата начала не может быть позже даты окончания');
          }
        }
        return this.errors.edit.start_date ?? errors;
      },
      stop_dateErrors() {
        const errors = [];
        if (this.start_date && this.stop_date) {
          const startDate = new Date(this.start_date);
          const stopDate = new Date(this.stop_date);
          if (startDate > stopDate) {
            errors.push('Дата начала не может быть позже даты окончания');
          }
        }
        // if (!this.$v.editedItem.date_to.$dirty) return errors;
        return this.errors.edit.start_date ?? errors;
      },
      // date_fromErrors() {
      //   const errors = [];
      //   if (!this.$v.editedItem.date_from.$dirty) return errors;
      //   if (!this.$v.editedItem.date_from.required) errors.push(MESSAGES.REQUIRED);
      //   return this.errors.edit.date_from ?? errors;
      // },
      start_dateFormatted() {
        return this.start_date ? this.$moment(this.start_date).format('DD.MM.YYYY') : '';
      },
      stop_dateFormatted() {
        return this.stop_date ? this.$moment(this.stop_date).format('DD.MM.YYYY') : '';
      },
      canChangeDates() {
        return this.editedItem.stop_date ? 
          new Date(this.$moment(this.editedItem.stop_date)) >= new Date() || this.$store.getters['auth/isAllowed']('activate_referralnumber')
          : true
      },
      sortedManagers() {
        return this.managers.sort( (a, b) => {
          const a1 = a.first_name || a.last_name ? `${a.last_name}${a.first_name} (${a.email})` : a.email;
          const b1 = b.first_name || b.last_name ? `${b.last_name}${b.first_name} (${b.email})` : b.email;
          return a1.toLowerCase().localeCompare(b1.toLowerCase()) 
        })
    },

    },
    methods: {
      ...mapActions({
        saveLink: 'profile/links/saveLink',
        loadManagers: 'users/managers/loadManagers',
      }),
      ...mapMutations({
        clearError: 'profile/links/clearError',
        setDialogEditShow: 'profile/links/setDialogEditShow',
      }),
      editConfirm() {
        if (this.loading.edit) return;
        this.$v.$touch();
        if (this.editedItem && !this.$v.$invalid) {
          const form = {
            ...this.editedItem,
            tests: [...this.selectedTests],
            start_date: this.start_date,
            stop_date: this.stop_date,
            // manager: this.managedBy,
          };
          if (this.user.user_type===MANAGER)
            form.manager = this.user.id
  
          this.saveLink(form).then((response) => {
            if (response) {
              this.dialogEditShow = false;
              console.log('dialogEditShow', this.dialogEditShow);
            } else {
              console.log(this.$v);
              this.$v.$touch();
            }
  
          });
        }
      },
    },
};
  
</script>
  